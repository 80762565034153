import * as React from "react";

export default function Page() {
  return (
    <div style={{ width: "100%", height: "100%", overflow: "hidden" }}>
      <iframe
        src="https://maroon161256.studio.site"
        style={{ width: "100%", height: "100%", border: "none" }}
        title="rkplus web from wix"
      />
    </div>
  );
}
